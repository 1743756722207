import React from "react";
//import { graphql, StaticQuery } from "gatsby";
//import styled from "styled-components";

function PreFooter() {
  return (
    <section className="bg-blue-800 max-w-screen-xl mx-auto pt-6 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="rounded-md bg-yellow-100 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Wichtige Informationen zu Affiliate-Links
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                *Alle Angaben ohne Gewähr. Änderungen sind in der Zwischenzeit
                möglich. Der Vergleich enthält Angebote diverser Tools, die bei
                Weiterleitung oder Verkauf eine Vergütung an lukaseuler.de
                bezahlen.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-md bg-yellow-100 p-4 mt-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Hinweis</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Die Bewertungen und Einschätzungen der Programme und Software
                ist eine rein subjektive Betrachtung, dich ich (Lukas Euler)
                nach bestem Wissen und Gewissen erstellt habe. Dennoch solltet
                ihr eine eigene Meinung bilden und die SEO Tools, falls möglich,
                eigenständig testen und ausprobieren.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreFooter;
