import React from "react";
//import { graphql, StaticQuery } from "gatsby";
//import styled from "styled-components";

function SeoToolsHeader() {
  return (
    <div className="bg-blue-800">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-yellow-700 tracking-wide uppercase">
            Ryte, Sistrix & Co.
          </h2>
          <h1 className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
            Die besten SEO-Tools 2021 🥇
          </h1>
          <p className="max-w-xl mx-auto text-sm text-gray-200">
            Letztes Update: 08.02.2021 - Neue Toolbewertung: Xovi & Sistrix
          </p>
        </div>
      </div>
    </div>
  );
}

export default SeoToolsHeader;
