import React, { useState } from "react";
import PropTypes from "prop-types";
import Rating from "@material-ui/lab/Rating";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import { withStyles } from "@material-ui/core/styles";
import { Listbox, Transition } from "@headlessui/react";

const StyledEuroRating = withStyles({
  iconFilled: {
    color: "#000000",
  },
})(Rating);

const Tools = ({ items }) => {
  // determined the items
  const [state, setState] = useState({
    toolsItems: items,
  });

  const getCategories = (things) => {
    let tempItems = things.map(({ node }) => {
      return node.tags;
    });
    var merged = [].concat.apply([], tempItems);
    var uniqueArray = merged.filter(function (item, pos) {
      return merged.indexOf(item) == pos;
    });
    categories = ["Alle", ...uniqueArray];
    categories = categories.sort();
    return categories;
  };

  const handleItems = (category) => {
    let tempItems = [...items];
    if (category === "Alle") {
      setState({
        ...state,
        toolsItems: tempItems,
      });
    } else {
      let items = tempItems.filter(({ node }) => node.tags.includes(category));
      setState({
        ...state,
        toolsItems: items,
      });
    }
  };

  var categories = getCategories(items);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  return (
    <div>
      <div className="py-6">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Übersicht
            </p>
            <h3 className="mt-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              SEO-Tools im Vergleich
            </h3>

            <div className="lg:flex items-center justify-center pt-8 pb-8">
              <div className="w-full max-w-xs">
                <Listbox
                  as="div"
                  className="space-y-1"
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm text-left leading-5 font-medium text-gray-700">
                        Kategorie wählen
                      </Listbox.Label>

                      <div className="relative">
                        <span className="inline-block w-full rounded-md shadow-sm">
                          <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            <span className="block truncate">
                              {selectedCategory}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <svg
                                className="h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                              >
                                <path
                                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                        </span>

                        <Transition
                          show={open}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="z-40 absolute mt-1 w-full rounded-md bg-white shadow-lg"
                        >
                          <Listbox.Options
                            static
                            className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                          >
                            {categories.map((category, index) => (
                              <Listbox.Option key={index} value={category}>
                                {({ selected, active }) => (
                                  <div
                                    onClick={() => {
                                      handleItems(category);
                                    }}
                                    className={`${
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900"
                                    } cursor-default select-none relative py-2 pl-8 pr-4`}
                                  >
                                    <span
                                      className={`${
                                        selected
                                          ? "font-semibold"
                                          : "font-normal"
                                      } block truncate`}
                                    >
                                      {category}
                                    </span>
                                    {selected && (
                                      <span
                                        className={`${
                                          active
                                            ? "text-white"
                                            : "text-blue-600"
                                        } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                      >
                                        <svg
                                          className="ml-2 h-5 w-5"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
          </div>
          <div className="relative max-w-7xl mx-auto">
            <ul className="max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
              {state.toolsItems.map(({ node }) => {
                return (
                  <li
                    key={node.id}
                    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
                  >
                    <div className="flex-1 flex flex-col p-4">
                      <img
                        className="h-16 flex-shrink-0 mx-auto bg-white rounded-md object-contain"
                        // src="https://it.ryte.com/_themes/default/imgs/branding/ryte.png?v=1611958907"
                        src={node.data.logo.fluid.src}
                        srcSet={node.data.logo.fluid.srcSet}
                        alt={node.data.logo.alt}
                        loading="lazy"
                      />
                      <dl className="mt-1 flex-grow flex flex-col justify-start">
                        <dt className="sr-only">Title</dt>

                        <div>
                          <div className="-mt-px flex">
                            <div className="w-0 flex-1 flex">
                              <div className="relative -mr-px w-0 flex-1 items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg">
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={node.data.rating}
                                  precision={0.25}
                                  readOnly
                                />
                                <p className="ml-2 font-semibold">
                                  Rating: {node.data.rating}/5
                                </p>
                              </div>
                            </div>
                            <div className="w-0 flex-1 flex">
                              <div className="relative -mr-px w-0 flex-1 items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg">
                                <StyledEuroRating
                                  name="half-rating-read"
                                  icon={
                                    <EuroSymbol
                                      style={{
                                        iconFilled: { color: "#ff6d75" },
                                      }}
                                      fontSize="inherit"
                                    />
                                  }
                                  defaultValue={node.data.preis}
                                  precision={0.5}
                                  readOnly
                                />
                                <p className="ml-2 font-semibold">
                                  {node.data.preis_bewertung.text}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <dd>
                          <div className="p-1 flex flex-wrap justify-center items-center h-16">
                            {node.tags.map((tag, index) => {
                              return (
                                <span
                                  key={index}
                                  className="row-span-2 m-1 px-3 py-1 text-blue-800 text-xs flex-wrap font-medium bg-blue-100 rounded-full"
                                >
                                  {tag}
                                </span>
                              );
                            })}
                          </div>
                          <blockquote className="m-3 pt-2 md:flex-grow md:flex md:flex-col">
                            <div className="relative text-xl italic leading-8 text-gray-500 md:flex-grow">
                              <svg
                                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-100"
                                fill="currentColor"
                                viewBox="0 0 32 32"
                                aria-hidden="true"
                              >
                                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                              </svg>
                              <p className="relative">
                                {node.data.review.text}
                              </p>
                            </div>
                          </blockquote>
                        </dd>
                        {/* <div className="mx-auto text-left">
                          {node.data.positives.map(({ pros }) => {
                            return (
                              <dt className="mx-auto text-left" key={pros.text}>
                                <div className="flex">
                                  <div className="flex-1 flex ">
                                    <p className="relative flex-1 inline-flex items-center py-1 text-sm text-left text-green-700 font-medium border border-transparent rounded-bl-lg">
                                      <svg
                                        className="h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                                        />
                                      </svg>
                                      <span className="ml-3">{pros.text}</span>
                                    </p>
                                  </div>
                                </div>
                              </dt>
                            );
                          })}

                          {node.data.negatives.map(({ cons }) => {
                            return (
                              <dt className="mx-auto text-left" key={cons.text}>
                                <div className="-mt-px flex">
                                  <div className="w-0 flex-1 flex">
                                    <p className="relative flex-1 inline-flex items-center py-1 text-sm text-left text-red-700 font-medium border border-transparent rounded-bl-lg">
                                      <svg
                                        className="h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
                                        />
                                      </svg>
                                      <span className="ml-3">{cons.text}</span>
                                    </p>
                                  </div>
                                </div>
                              </dt>
                            );
                          })}
                        </div> */}
                      </dl>
                    </div>
                    <div>
                      <dl className="grid grid-cols-1 bg-white rounded-b-lg overflow-hidden divide-y divide-gray-200 md:grid-cols-1 md:divide-y-0 md:divide-x">
                        <div className="-mt-px flex divide-y divide-x divide-gray-400">
                          <div className="w-0 flex-1 flex hover:bg-orange-600">
                            <a
                              href={node.data.website.url}
                              target="_blank"
                              rel="noopener noreferrer sponsored"
                              className="relative -mr-px w-0 flex-1 inline-flex justify-center py-2 text-sm text-black hover:text-white font-medium border border-transparent rounded-b-lg"
                            >
                              <span className="ml-3 p-2 font-bold">
                                {node.data.link_text.text}
                              </span>
                            </a>
                          </div>
                        </div>
                      </dl>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="rounded-md bg-blue-100 p-4 mt-8">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-blue-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  Falls du ein Tool vermisst oder einen Fehler findest, dann
                  schreib mir gerne per{" "}
                  <a href="mailto:seo@lukaseuler.de">E-Mail</a> eine Nachricht.
                </p>
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <a
                    href="mailto:seo@lukaseuler.de"
                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  >
                    E-Mail schreiben <span aria-hidden="true">&rarr;</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tools.propTypes = {
  items: PropTypes.array,
  toolItems: PropTypes.object,
};

export default Tools;
