import React from "react";
//import { graphql, StaticQuery } from "gatsby";
//import styled from "styled-components";

function Testimonial() {
  return (
<section className="bg-blue-800">
  <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
    <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-blue-900 lg:pr-16">
      <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
        <div className="relative text-lg font-medium text-white md:flex-grow">
          <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p className="relative">
            Diese Übersicht hat mir extrem geholfen und war eine super Ausgangslage, um die passenden Tools für mein Geschäft zu finden! Jetzt habe ich einen besseren Überblick und konnte unseren lokalen Rankings verbessern!
          </p>
        </div>
        <footer className="mt-8">
          <div className="flex">
            <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img className="h-12 w-12 rounded-full" loading="lazy" src="https://images.unsplash.com/photo-1525054974-849f88188c3e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
            </div>
            <div className="ml-4">
              <div className="text-base font-medium text-white">Marc S.</div>
              <div className="text-base font-medium text-blue-200">Ladenbesitzer</div>
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
    <div className="py-12 px-4 border-t-2 border-blue-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
      <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
        <div className="relative text-lg font-medium text-white md:flex-grow">
          <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600" fill="currentColor" viewBox="0 0 32 32">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p className="relative">
            Diese Liste habe ich sofort abgespeichert, weil ich so up-to-date bleibe, was sich im SEO-Tool-Markt verändert und ob es neue hilfreiche Tools gibt! Dafür ein großes Dankeschön 👍 So kann ich schnell abschätzen ob sich ein neues Produkt lohnt.
          </p>
        </div>
        <footer className="mt-8">
          <div className="flex">
            <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img className="h-12 w-12 rounded-full" loading="lazy" src="https://images.unsplash.com/photo-1611876561864-fd2d32020b15?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
            </div>
            <div className="ml-4">
              <div className="text-base font-medium text-white">Elisa B.</div>
              <div className="text-base font-medium text-blue-200">Online Marketing Managerin</div>
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  </div>
</section>
  );
}

export default Testimonial;
