import React from "react";
import { graphql } from 'gatsby'
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SeoToolsHeader from "../components/seotools/header";
import TopThree from "../components/seotools/topThree";
import Tools from "../components/seotools/Tools";
//import Changelog from "../components/seotools/Changelog";
import Testimonial from "../components/seotools/Testimonial";
//import FAQ from "../components/seotools/FAQ";
import PreFooter from "../components/seotools/PreFooter";

const SeoToolsPage = ({ data }) => (
    <Layout>
      <SEO
        keywords={[`seo`, `tools`]}
        title="Die besten SEO-Tools 2021 🥇 Ryte, Sistrix & Co."
        description="SEO Tools zur Webseiten-Optimierung im Vergleich: SEO-Check ✅ Ranktracker ✅ Link Analyse ✅ Keyword-Analyse ✅"
        robots="index,follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <SeoToolsHeader className="relative bg-blue-800 overflow-hidden" />
      <TopThree/>
      <Tools items={data.allPrismicSeoTools.edges}/>
      <Testimonial/>
      {/* <FAQ/> */}
      <PreFooter/>
    </Layout>
  );

export const query = graphql`
{
  allPrismicSeoTools(sort: {fields: data___rating, order: DESC}) {
    edges {
      node {
        id
        data {
          name {
            text
          }
          rating
          preis
          preis_bewertung {
            text
          }
          review {
            text
          }
          website {
            url
          }
          link_text {
            text
          }
          logo {
            alt
            fluid {
              src
              srcSet
            }
          }
          negatives {
            cons {
              text
            }
          }
          positives {
            pros {
              text
            }
          }
        }
        tags
      }
    }
  }
}
`

SeoToolsPage.propTypes = {
  data: PropTypes.object,
};

export default SeoToolsPage;
